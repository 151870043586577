<app-header-style-one></app-header-style-one>
<div class="container">
  <!-- <div id="myNav" class="overlay">
    <a href="javascript:void(0)" class="closebtn" (click)="closeNav()">&times;</a>
    <div class="overlay-content">
      <a target="_blank" href="https://youtu.be/kr5Oy1fS7Rc">Demo!</a>
    </div>
  </div>

  <span class="help" (click)="openNav()">&#9776; help</span> -->

  <span class="help"><a target="_blank" href="https://youtu.be/kr5Oy1fS7Rc">Click here for a Demo</a></span>


  <div class="row">
    <div class="col-md-6">
      <h2 class="section-heading">Code Editor</h2>
      <textarea class="html-input card" [(ngModel)]="currentQuery"></textarea>
      <hr>
      <div class="grid-container">
       
        <button type="button" class="btn btn-pink mb-4" data-bs-toggle="modal" data-bs-target="#myModal">
          <b>Add HTML Tag(s)</b>
        </button>
      

      <div class="modal fade align-center" id="myModal">
        <div class="modal-dialog">
          <div class="modal-content">

            <div class="modal-header">
              <h4 class="modal-title">Select a tag</h4>
              <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
            </div>
      
              <div class="modal-body">
                <button class="btn btn-purple m-1"  data-bs-dismiss="modal" (click)="addTag('Heading Tag')">Heading Tag</button>
                <button class="btn btn-orange m-1" data-bs-dismiss="modal" (click)="addTag('Heading Tag with Style')">Heading Tag with Style</button>
                <button class="btn btn-orange m-1" data-bs-dismiss="modal" (click)="addTag('Link (a) Tag')">Link (a) Tag</button>
                <button class="btn btn-pink m-1" data-bs-dismiss="modal" (click)="addTag('Strong Tag')">Strong Tag</button>
                <div class="dropdown-divider"></div>
                <button class="btn btn-pink m-1" data-bs-dismiss="modal" (click)="addTag('Paragraph Tag')">Paragraph Tag</button>
                <button class="btn btn-purple m-1" data-bs-dismiss="modal" (click)="addTag('Paragraph with link (a) Tag')">Paragraph with link (a) Tag</button>
                <button class="btn btn-purple m-1" data-bs-dismiss="modal" (click)="addTag('Bullet List Tag')">Bullet List Tag</button>
                <button class="btn btn-orange m-1" data-bs-dismiss="modal" (click)="addTag('Every Tag')">Add all!</button>
               </div>
      
            <div class="modal-footer">
              <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Close</button>
            </div>
      
          </div>
        </div>
      </div>

      &nbsp;&nbsp;
      <a class="btn btn-orange mb-4" (click)="clearHtml()">
        <b>Clear HTML</b>
      </a>

      </div>
    </div>
    <div class="col-md-6">
      <h2 class="section-heading2">Rendered Result</h2>
      <p class="html-result card" [innerHTML]="currentQuery | safeHtml"></p>
      <hr>
      <a class="btn btn-orange mb-4" (click)="saveCode()">
        <b>Save Code</b>
      </a>
      &nbsp;&nbsp;
      <a class="btn btn-pink mb-4" (click)="runScript()">
        <b>Run Javascript</b>
      </a>
      &nbsp;&nbsp;
      <a class="btn btn-orange mb-4" (click)="retrieveCode()">
        <b>Retrieve Code</b>
      </a>
    </div>
  </div>
</div>

<div #scriptInject id="inject-script">
</div>