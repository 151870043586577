import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import Swal from 'sweetalert2';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
  selector: 'app-code-play',
  templateUrl: './code-play.component.html',
  styleUrls: ['./code-play.component.css'],
  animations: [
    trigger('expandCollapse', [
      state('expanded', style({ height: '*' })),
      state('collapsed', style({ height: '0', overflow: 'hidden' })),
      transition('expanded <=> collapsed', animate('300ms ease-in-out'))
    ])
  ]
})

export class CodePlayComponent implements OnInit {
  @ViewChild('scriptInject') scriptInject!: ElementRef;
  data: any;
  constructor() {}
  test = '';
  scriptFromQuery = `alert('hello Shoby!')`;

  currentQuery: string = 
  `<style>
.demo
{
 color: red;
}

h2
{
 color: purple;
}
</style>

<script>
 var x = 5;
 var y = 6;
 var z = x + y;
 document.getElementById("demo").innerHTML =
 "Javascript Demo: Result of x + y is -> " + z;

alert("Javascript Demo: Result of x + y is -> " + z);
</script>
  
<h2>Benefits of coding for Kids</h2>

<ul>
  <li>Fun and Satisfying</li>
  <li>Creativity, Thinking Fluidity</li>
  <li>Problem solving</li>
  <li>Computational Thinking</li>
  <li>Presentation skills</li>
  <li>Learning to learn</li>
  <li>Improves Collaboration</li>
  <li>Improves Communication</li>
</ul>

<strong>
<p id="demo" class="demo">Javascript Demo - Click "Run Javascript Button"</p>
</strong>`;

  ngOnInit(): void {
  }

  clearHtml() {
    this.currentQuery = '';
  }

  addTag(tag: string): void{
    var modal = document.getElementById("myModal");
    modal.style.display = "none";
    Swal.fire(`${tag} has been added!`);

    switch(tag) {
      case 'Heading Tag':
      this.currentQuery = this.currentQuery + 
      `\r\n<h1>heading</h1>\r\n`;
      break;
      case 'Heading Tag with Style':
      this.currentQuery = this.currentQuery + 
      `\r\n<h1 style='color:red;'>heading with style</h1>\r\n`;
      break;
      case 'Link (a) Tag':
      this.currentQuery = this.currentQuery + 
      `\r\n<a href='https://www.apple.com/' target='_blank'>Link (a) tag. A link to Apple Website</a>\r\n`;
      break;
      case 'Paragraph Tag':
      this.currentQuery = this.currentQuery + 
      `\r\n<p>paragraph</p>\r\n`;
      break;
      case 'Paragraph with link (a) Tag':
      this.currentQuery = this.currentQuery + 
      `\r\n<p>paragraph with link to <a href='http://www.apple.com' target='_blank'>Apple Website</a></p>\r\n`;
      break;
      case 'Strong Tag':
      this.currentQuery = this.currentQuery + 
      `\r\n<strong>Strong tag</strong>\r\n`;
      break;
      case 'Bullet List Tag':
      this.currentQuery = this.currentQuery + 
      `\r\n<ul>\r\n <li>Bullet Point 1</li>\r\n <li>Bullet Point 2</li>\r\n</ul>\r\n`;
      break;
      case 'Every Tag':
      this.currentQuery =  
      `\r\n<h1>heading</h1>\r\n`;
      
      this.currentQuery = this.currentQuery + 
      `\r\n<h1 style='color:red;'>heading with style</h1>\r\n`;
      
      this.currentQuery = this.currentQuery + 
      `\r\n<a href='https://www.apple.com/' target='_blank'>Link (a) tag. A link to Apple Website</a>\r\n`;
      
      this.currentQuery = this.currentQuery + 
      `\r\n<p>paragraph</p>\r\n`;
      
      this.currentQuery = this.currentQuery + 
      `\r\n<p>paragraph with link to <a href='http://www.apple.com' target='_blank'>Apple Website</a></p>\r\n`;
      
      this.currentQuery = this.currentQuery + 
      `\r\n<b>bold</b>\r\n`;
      
      this.currentQuery = this.currentQuery + 
      `\r\n<ul>\r\n <li>Bullet Point 1</li>\r\n <li>Bullet Point 2</li>\r\n</ul>\r\n`;
      break;
      
      default:
      break;
    }
  }

  saveCode() {
    this.data = this.currentQuery;
    console.log("data2", this.data);
    localStorage.setItem('code', this.data)
    Swal.fire('Code saved!');
  }

  retrieveCode() {
    console.log(localStorage.getItem('code'));
    // @ts-ignore
    this.currentQuery = localStorage.getItem('code');
    Swal.fire('Code retrieved!');
  
  }


  openNav() {
    document.getElementById("myNav")!.style.width = "100%";
  }

  closeNav() {
    document.getElementById("myNav")!.style.width = "0%";
  }

  runScript(): void {
    var result = this.currentQuery.replace(/[\n\r]+/g, '').match(/<script>(.*?)<\/script>/g)?.map(function (val) {
      return val.replace(/<\/?script>/g, '');
    });

     // @ts-ignore
    this.scriptFromQuery = result.join();

    
    var scriptContainer = this.scriptInject.nativeElement;
    scriptContainer.innerHTML = '';

    var scriptInnerContainer = document.createElement("div");
    var scriptTag = document.createElement("span");

    scriptInnerContainer.appendChild(scriptTag);
    scriptContainer.appendChild(scriptInnerContainer);

    var script = document.createElement("script");
    script.type = "text/javascript";

    if (this.scriptFromQuery) {
      script.innerHTML = this.scriptFromQuery;
    }
    var parent = scriptTag.parentElement;
     // @ts-ignore
    parent.parentElement.replaceChild(script, parent);
  }
}