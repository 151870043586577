<footer class="footer-area">
    <div class="container">
        <div class="row">
            <div class="col-md-4">
                <div class="single-footer-widget mb-30">
                    <div class="logo">
                        <a routerLink="/" class="d-inline-block"><img class="logo-image" src="assets/img/full-round-logo.png" alt="image"></a>
                    </div>
                    <div class="newsletter-box">
                        <p>© CodeForYoung is Proudly Owned by <a>Xhobb Ltd</a></p>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="single-footer-widget mb-30">
                    <h3>Contact Us</h3>
                    <ul class="contact-us-link">
                        <li>
                            <i class='bx bx-map'></i>
                            <a href="#" target="_blank">Bristol, United Kingdom</a>
                        </li>
                        <!-- <li>
                            <i class='bx bx-phone-call'></i>
                            <a href="tel:+11234567890">+44 (07) 595 386964</a>
                        </li> -->
                        <li>
                            <i class='bx bx-envelope'></i>
                            <a href="mailto:info@codeforyoung.com">info@codeforyoung.com</a>
                        </li>
                    </ul>
                    <ul class="social-link">
                        <li><a href="https://www.youtube.com/channel/UCsC_H0-M_lMdu2CY9qc8sFA" class="d-block" target="_blank"><i class='bx bxl-youtube'></i></a></li>
                    </ul>
                </div>
            </div>
            <!-- <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget mb-30">
                    <h3>Support</h3>
                    <ul class="support-link">
                        <li><a routerLink="/">Privacy</a></li>
                        <li><a routerLink="/">FAQ's</a></li>
                        <li><a routerLink="/">Support</a></li>
                        <li><a routerLink="/">Terms</a></li>
                        <li><a routerLink="/">Condition</a></li>
                        <li><a routerLink="/">Policy</a></li>
                    </ul>
                </div>
            </div> -->
            
            <div class="col-md-4">
                <div class="single-footer-widget mb-30">
                    <h3>Newsletter</h3>
                    <div class="newsletter-box">
                        <p>To get the latest news and latest updates from us.</p>
                        <form class="newsletter-form">
                            <label>Your e-mail address:</label>
                            <input type="email" class="input-newsletter" placeholder="Enter your email" name="EMAIL">
                            <button type="submit">Subscribe</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="footer-bottom-area">
        <div class="container">
            
        </div>
    </div>
</footer>

<ngx-scrolltop></ngx-scrolltop>