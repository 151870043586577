import { Component } from '@angular/core';

@Component({
  selector: 'app-premium-courses',
  templateUrl: './premium-courses.component.html',
  styleUrls: ['./premium-courses.component.scss']
})
export class PremiumCoursesComponent {
  currentTab = 'tab1';
    switchTab(event: MouseEvent, tab: string) {
        event.preventDefault();
        this.currentTab = tab;
    }
}
