import {Component, OnInit} from '@angular/core';
import {NgxGalleryLayout, NgxGalleryOptions} from '@kolkov/ngx-gallery';
import {NgxGalleryImage} from '@kolkov/ngx-gallery';
import {NgxGalleryAnimation} from '@kolkov/ngx-gallery';

@Component({
  selector: 'app-course-four',
  templateUrl: './course4.component.html',
  styleUrls: ['./course4.component.css']
})
export class CourseFourComponent implements OnInit {
  galleryOptions: NgxGalleryOptions[] = [];
  galleryImages: NgxGalleryImage[] = [];
  
  constructor() { }

  ngOnInit() {
    this.galleryOptions = [
      {
        //width: '600px',
        //height: '400px',
        thumbnailsColumns: 3,
        imageAnimation: NgxGalleryAnimation.Slide,
        //thumbnailsRemainingCount: true,
      },
      // max-width 800
      {
        //breakpoint: 800,
        //width: '100%',
        //height: '600px',
        //imagePercent: 80,
        // thumbnailsPercent: 20,
        // thumbnailsMargin: 20,
        // thumbnailMargin: 20,
        layout: NgxGalleryLayout.ThumbnailsTop,
        thumbnailsArrows: true
      },
      // max-width 400
      {
        breakpoint: 400,
        preview: false
      }
    ];

    this.galleryImages = [
      {
        small: 'assets/images/A1.jpg',
        medium: 'assets/images/A1.jpg',
        big: 'assets/images/A1.jpg'
      },{
        small: 'assets/images/A2.jpg',
        medium: 'assets/images/A2.jpg',
        big: 'assets/images/A2.jpg'
      }
      ,{
        small: 'assets/images/A3.jpg',
        medium: 'assets/images/A3.jpg',
        big: 'assets/images/A3.jpg'
      }
      ,{
        small: 'assets/images/A4.jpg',
        medium: 'assets/images/A4.jpg',
        big: 'assets/images/A4.jpg'
      },{
        small: 'assets/images/A5.jpg',
        medium: 'assets/images/A5.jpg',
        big: 'assets/images/A5.jpg'
      },{
        small: 'assets/images/A6.jpg',
        medium: 'assets/images/A6.jpg',
        big: 'assets/images/A6.jpg'
      },{
        small: 'assets/images/A7.jpg',
        medium: 'assets/images/A7.jpg',
        big: 'assets/images/A7.jpg'
      },{
        small: 'assets/images/A8.jpg',
        medium: 'assets/images/A8.jpg',
        big: 'assets/images/A8.jpg'
      },{
        small: 'assets/images/A9.jpg',
        medium: 'assets/images/A9.jpg',
        big: 'assets/images/A9.jpg'
      }
      ,{
        small: 'assets/images/A10.jpg',
        medium: 'assets/images/A10.jpg',
        big: 'assets/images/A10.jpg'
      }

    ];
  }
}