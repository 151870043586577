<div class="courses-area pt-100 pb-70">
    <div class="container">
        <div class="section-title text-start">
            <span class="sub-title">Discover Quick Peeks</span>
            <h2>Quick Peeks</h2>
        </div>
        <!-- <ul class="nav-tabset">
            <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab1'}">
                <span class="active" (click)="switchTab($event, 'tab1')">All (06)</span>
            </li>
            <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab2'}">
                <span (click)="switchTab($event, 'tab2')">Business (02)</span>
            </li>
            <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab3'}">
                <span (click)="switchTab($event, 'tab3')">Design (05)</span>
            </li>
            <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab4'}">
                <span (click)="switchTab($event, 'tab4')">Development (04)</span>
            </li>
            <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab5'}">
                <span (click)="switchTab($event, 'tab5')">Language (02)</span>
            </li>
            <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab6'}">
                <span (click)="switchTab($event, 'tab6')">Management (03)</span>
            </li>
            <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab7'}">
                <span (click)="switchTab($event, 'tab7')">Photography (04)</span>
            </li>
        </ul> -->
        <div class="tabs-container">
            <div class="pane" id="tab1" *ngIf="currentTab === 'tab1'">
                <div class="row">
                    <div class="col-lg-4 col-md-6">
                        <div class="single-courses-box mb-30">
                            <div class="courses-image">
                                <a routerLink="/course1" class="d-block"><img src="assets/images/HTMLll.jpg" alt="image"></a>
                                <div class="courses-tag">
                                    <a routerLink="/" class="d-block">Free</a>
                                </div>
                            </div>
                            <div class="courses-content">
                                <h3><a routerLink="/course1" class="d-inline-block">HTML: Building Web Foundations</a></h3>
                                <div class="courses-rating">
                                    <div class="review-stars-rated">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                    </div>
                                    <div class="rating-total">
                                        5.0 (1 rating)
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="courses-box-footer">
                                <ul>
                                    <li class="students-number">
                                        <i class='bx bx-user'></i> 10 students
                                    </li>
                                    <li class="courses-lesson">
                                        <i class='bx bx-book-open'></i> 6 lessons
                                    </li>
                                    <li class="courses-price">
                                        Free
                                    </li>
                                </ul>
                            </div> -->
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="single-courses-box mb-30">
                            <div class="courses-image">
                                <a routerLink="/course2" class="d-block"><img src="assets/images/Csss.jpg" alt="image"></a>
                                <div class="courses-tag">
                                    <a routerLink="/" class="d-block">Free</a>
                                </div>
                            </div>
                            <div class="courses-content">
                                <h3><a routerLink="/course2" class="d-inline-block">CSS: Starting Styles</a></h3>
                                <div class="courses-rating">
                                    <div class="review-stars-rated">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                    </div>
                                    <div class="rating-total">
                                        5.0 (1 rating)
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="courses-box-footer">
                                <ul>
                                    <li class="students-number">
                                        <i class='bx bx-user'></i> 10 students
                                    </li>
                                    <li class="courses-lesson">
                                        <i class='bx bx-book-open'></i> 6 lessons
                                    </li>
                                    <li class="courses-price">
                                        Free
                                    </li>
                                </ul>
                            </div> -->
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="single-courses-box mb-30">
                            <div class="courses-image">
                                <a routerLink="/course3" class="d-block"><img src="assets/images/Jss.jpg" alt="image"></a>
                                <div class="courses-tag">
                                    <a routerLink="/" class="d-block">Free</a>
                                </div>
                            </div>
                            <div class="courses-content">
                                <h3><a routerLink="/course3" class="d-inline-block">JavaScript: Dynamic Code Intro</a></h3>
                                <div class="courses-rating">
                                    <div class="review-stars-rated">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                    </div>
                                    <div class="rating-total">
                                        5.0 (2 ratings)
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="courses-box-footer">
                                <ul>
                                    <li class="students-number">
                                        <i class='bx bx-user'></i> 10 students
                                    </li>
                                    <li class="courses-lesson">
                                        <i class='bx bx-book-open'></i> 6 lessons
                                    </li>
                                    <li class="courses-price">
                                        Free
                                    </li>
                                </ul>
                            </div> -->
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="single-courses-box mb-30">
                            <div class="courses-image">
                                <a routerLink="/course4" class="d-block"><img src="assets/images/Aii_.jpg" alt="image"></a>
                                <div class="courses-tag">
                                    <a routerLink="/" class="d-block">Free</a>
                                </div>
                            </div>
                            <div class="courses-content">
                                <h3><a routerLink="/course4" class="d-inline-block">AI: Exploring Intelligent Machines</a></h3>
                                <div class="courses-rating">
                                    <div class="review-stars-rated">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                    </div>
                                    <div class="rating-total">
                                        4.8 (3 ratings)
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="courses-box-footer">
                                <ul>
                                    <li class="students-number">
                                        <i class='bx bx-user'></i> 10 students
                                    </li>
                                    <li class="courses-lesson">
                                        <i class='bx bx-book-open'></i> 6 lessons
                                    </li>
                                    <li class="courses-price">
                                        Free
                                    </li>
                                </ul>
                            </div> -->
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="single-courses-box mb-30">
                            <div class="courses-image">
                                <a routerLink="/course5" class="d-block"><img src="assets/images/WTII.jpg" alt="image"></a>
                                <div class="courses-tag">
                                    <a routerLink="/" class="d-block">Free</a>
                                </div>
                            </div>
                            <div class="courses-content">
                                <h3><a routerLink="/course5" class="d-inline-block">Web Trio: Foundations Introduced</a></h3>
                                <div class="courses-rating">
                                    <div class="review-stars-rated">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                    </div>
                                    <div class="rating-total">
                                        4.9 (7 ratings)
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="courses-box-footer">
                                <ul>
                                    <li class="students-number">
                                        <i class='bx bx-user'></i> 10 students
                                    </li>
                                    <li class="courses-lesson">
                                        <i class='bx bx-book-open'></i> 6 lessons
                                    </li>
                                    <li class="courses-price">
                                        Free
                                    </li>
                                </ul>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>