import {Component, OnInit} from '@angular/core';
import {NgxGalleryLayout, NgxGalleryOptions} from '@kolkov/ngx-gallery';
import {NgxGalleryImage} from '@kolkov/ngx-gallery';
import {NgxGalleryAnimation} from '@kolkov/ngx-gallery';

@Component({
  selector: 'app-course-two',
  templateUrl: './course2.component.html',
  styleUrls: ['./course2.component.css']
})
export class CourseTwoComponent implements OnInit {
  galleryOptions: NgxGalleryOptions[] = [];
  galleryImages: NgxGalleryImage[] = [];
  
  constructor() { }

  ngOnInit() {
    this.galleryOptions = [
      {
        //width: '600px',
        //height: '400px',
        thumbnailsColumns: 3,
        imageAnimation: NgxGalleryAnimation.Slide,
        //thumbnailsRemainingCount: true,
      },
      // max-width 800
      {
        //breakpoint: 800,
        //width: '100%',
        //height: '600px',
        //imagePercent: 80,
        // thumbnailsPercent: 20,
        // thumbnailsMargin: 20,
        // thumbnailMargin: 20,
        layout: NgxGalleryLayout.ThumbnailsTop,
        thumbnailsArrows: true
      },
      // max-width 400
      {
        breakpoint: 400,
        preview: false
      }
    ];

    this.galleryImages = [
      {
        small: 'assets/images/C1.jpg',
        medium: 'assets/images/C1.jpg',
        big: 'assets/images/C1.jpg'
      },{
        small: 'assets/images/C2.jpg',
        medium: 'assets/images/C2.jpg',
        big: 'assets/images/C2.jpg'
      }
      ,{
        small: 'assets/images/C3.jpg',
        medium: 'assets/images/C3.jpg',
        big: 'assets/images/C3.jpg'
      }
      ,{
        small: 'assets/images/C4.jpg',
        medium: 'assets/images/C4.jpg',
        big: 'assets/images/C4.jpg'
      },{
        small: 'assets/images/C5.jpg',
        medium: 'assets/images/C5.jpg',
        big: 'assets/images/C5.jpg'
      },{
        small: 'assets/images/C6.jpg',
        medium: 'assets/images/C6.jpg',
        big: 'assets/images/C6.jpg'
      },{
        small: 'assets/images/C7.jpg',
        medium: 'assets/images/C7.jpg',
        big: 'assets/images/C7.jpg'
      },{
        small: 'assets/images/C8.jpg',
        medium: 'assets/images/C8.jpg',
        big: 'assets/images/C8.jpg'
      },{
        small: 'assets/images/C9.jpg',
        medium: 'assets/images/C9.jpg',
        big: 'assets/images/C9.jpg'
      }

    ];
  }
}