import {Component, OnInit} from '@angular/core';
import {NgxGalleryLayout, NgxGalleryOptions} from '@kolkov/ngx-gallery';
import {NgxGalleryImage} from '@kolkov/ngx-gallery';
import {NgxGalleryAnimation} from '@kolkov/ngx-gallery';

@Component({
  selector: 'app-course-five',
  templateUrl: './course5.component.html',
  styleUrls: ['./course5.component.css']
})
export class CourseFiveComponent implements OnInit {
  galleryOptions: NgxGalleryOptions[] = [];
  galleryImages: NgxGalleryImage[] = [];
  
  constructor() { }

  ngOnInit() {
    this.galleryOptions = [
      {
        //width: '600px',
        //height: '400px',
        thumbnailsColumns: 3,
        imageAnimation: NgxGalleryAnimation.Slide,
        //thumbnailsRemainingCount: true,
      },
      // max-width 800
      {
        //breakpoint: 800,
        //width: '100%',
        //height: '600px',
        //imagePercent: 80,
        // thumbnailsPercent: 20,
        // thumbnailsMargin: 20,
        // thumbnailMargin: 20,
        layout: NgxGalleryLayout.ThumbnailsTop,
        thumbnailsArrows: true
      },
      // max-width 400
      {
        breakpoint: 400,
        preview: false
      }
    ];

    this.galleryImages = [
      {
        small: 'assets/images/H1.jpg',
        medium: 'assets/images/H1.jpg',
        big: 'assets/images/H1.jpg'
      },{
        small: 'assets/images/H2.jpg',
        medium: 'assets/images/H2.jpg',
        big: 'assets/images/H2.jpg'
      }
      ,{
        small: 'assets/images/H3.jpg',
        medium: 'assets/images/H3.jpg',
        big: 'assets/images/H3.jpg'
      }
      ,{
        small: 'assets/images/H4.jpg',
        medium: 'assets/images/H4.jpg',
        big: 'assets/images/H4.jpg'
      },{
        small: 'assets/images/H5.jpg',
        medium: 'assets/images/H5.jpg',
        big: 'assets/images/H5.jpg'
      },{
        small: 'assets/images/H6.jpg',
        medium: 'assets/images/H6.jpg',
        big: 'assets/images/H6.jpg'
      },{
        small: 'assets/images/H7.jpg',
        medium: 'assets/images/H7.jpg',
        big: 'assets/images/H7.jpg'
      },{
        small: 'assets/images/H8.jpg',
        medium: 'assets/images/H8.jpg',
        big: 'assets/images/H8.jpg'
      },{
        small: 'assets/images/H9.jpg',
        medium: 'assets/images/H9.jpg',
        big: 'assets/images/H9.jpg'
      }, 
      {
        small: 'assets/images/C1.jpg',
        medium: 'assets/images/C1.jpg',
        big: 'assets/images/C1.jpg'
      },{
        small: 'assets/images/C2.jpg',
        medium: 'assets/images/C2.jpg',
        big: 'assets/images/C2.jpg'
      }
      ,{
        small: 'assets/images/C3.jpg',
        medium: 'assets/images/C3.jpg',
        big: 'assets/images/C3.jpg'
      }
      ,{
        small: 'assets/images/C4.jpg',
        medium: 'assets/images/C4.jpg',
        big: 'assets/images/C4.jpg'
      },{
        small: 'assets/images/C5.jpg',
        medium: 'assets/images/C5.jpg',
        big: 'assets/images/C5.jpg'
      },{
        small: 'assets/images/C6.jpg',
        medium: 'assets/images/C6.jpg',
        big: 'assets/images/C6.jpg'
      },{
        small: 'assets/images/C7.jpg',
        medium: 'assets/images/C7.jpg',
        big: 'assets/images/C7.jpg'
      },{
        small: 'assets/images/C8.jpg',
        medium: 'assets/images/C8.jpg',
        big: 'assets/images/C8.jpg'
      },{
        small: 'assets/images/C9.jpg',
        medium: 'assets/images/C9.jpg',
        big: 'assets/images/C9.jpg'
      },
      {
        small: 'assets/images/J1.jpg',
        medium: 'assets/images/J1.jpg',
        big: 'assets/images/J1.jpg'
      },{
        small: 'assets/images/J2.jpg',
        medium: 'assets/images/J2.jpg',
        big: 'assets/images/J2.jpg'
      }
      ,{
        small: 'assets/images/J3.jpg',
        medium: 'assets/images/J3.jpg',
        big: 'assets/images/J3.jpg'
      }
      ,{
        small: 'assets/images/J4.jpg',
        medium: 'assets/images/J4.jpg',
        big: 'assets/images/J4.jpg'
      },{
        small: 'assets/images/J5.jpg',
        medium: 'assets/images/J5.jpg',
        big: 'assets/images/J5.jpg'
      },{
        small: 'assets/images/J6.jpg',
        medium: 'assets/images/J6.jpg',
        big: 'assets/images/J6.jpg'
      },{
        small: 'assets/images/J7.jpg',
        medium: 'assets/images/J7.jpg',
        big: 'assets/images/J7.jpg'
      },{
        small: 'assets/images/J8.jpg',
        medium: 'assets/images/J8.jpg',
        big: 'assets/images/J8.jpg'
      },{
        small: 'assets/images/J9.jpg',
        medium: 'assets/images/J9.jpg',
        big: 'assets/images/J9.jpg'
      },
      {
        small: 'assets/images/A1.jpg',
        medium: 'assets/images/A1.jpg',
        big: 'assets/images/A1.jpg'
      },{
        small: 'assets/images/A2.jpg',
        medium: 'assets/images/A2.jpg',
        big: 'assets/images/A2.jpg'
      }
      ,{
        small: 'assets/images/A3.jpg',
        medium: 'assets/images/A3.jpg',
        big: 'assets/images/A3.jpg'
      }
      ,{
        small: 'assets/images/A4.jpg',
        medium: 'assets/images/A4.jpg',
        big: 'assets/images/A4.jpg'
      },{
        small: 'assets/images/A5.jpg',
        medium: 'assets/images/A5.jpg',
        big: 'assets/images/A5.jpg'
      },{
        small: 'assets/images/A6.jpg',
        medium: 'assets/images/A6.jpg',
        big: 'assets/images/A6.jpg'
      },{
        small: 'assets/images/A7.jpg',
        medium: 'assets/images/A7.jpg',
        big: 'assets/images/A7.jpg'
      },{
        small: 'assets/images/A8.jpg',
        medium: 'assets/images/A8.jpg',
        big: 'assets/images/A8.jpg'
      },{
        small: 'assets/images/A9.jpg',
        medium: 'assets/images/A9.jpg',
        big: 'assets/images/A9.jpg'
      }
      ,{
        small: 'assets/images/A10.jpg',
        medium: 'assets/images/A10.jpg',
        big: 'assets/images/A10.jpg'
      }

    ];
  }
}