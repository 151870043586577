<app-header-style-one></app-header-style-one>
<div class="courses-area pt-100 pb-70">
    <div class="container">
        <div class="section-title text-start">
            <span class="sub-title">Discover Courses</span>
            <h2>Our Premium Courses</h2>
        </div>
        <!-- <ul class="nav-tabset">
            <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab1'}">
                <span class="active" (click)="switchTab($event, 'tab1')">All (06)</span>
            </li>
            <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab2'}">
                <span (click)="switchTab($event, 'tab2')">Business (02)</span>
            </li>
            <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab3'}">
                <span (click)="switchTab($event, 'tab3')">Design (05)</span>
            </li>
            <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab4'}">
                <span (click)="switchTab($event, 'tab4')">Development (04)</span>
            </li>
            <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab5'}">
                <span (click)="switchTab($event, 'tab5')">Language (02)</span>
            </li>
            <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab6'}">
                <span (click)="switchTab($event, 'tab6')">Management (03)</span>
            </li>
            <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab7'}">
                <span (click)="switchTab($event, 'tab7')">Photography (04)</span>
            </li>
        </ul> -->
        <div class="tabs-container">
            <div class="pane" id="tab1" *ngIf="currentTab === 'tab1'">
                <div class="row">
                    <div class="col-lg-4 col-md-6">
                        <div class="single-courses-box mb-30">
                            <div class="courses-image">
                                <a routerLink="/course1" class="d-block"><img src="assets/images/CSN1.jpg"
                                        alt="image"></a>
                                <div class="courses-tag">
                                    <a routerLink="/" class="d-block">Premium</a>
                                </div>
                            </div>
                            <div class="courses-content">
                                <h3><a routerLink="/" class="d-inline-block">Building Blocks of the Web - A Kid's Guide to HTML & CSS</a></h3>
                                <div class="courses-rating">
                                    <div class="review-stars-rated">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                    </div>
                                    <div class="rating-total">
                                        4.8 (7 ratings)
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="courses-box-footer">
                                <ul>
                                    <li class="students-number">
                                        <i class='bx bx-user'></i> 10 students
                                    </li>
                                    <li class="courses-lesson">
                                        <i class='bx bx-book-open'></i> 6 lessons
                                    </li>
                                    <li class="courses-price">
                                        Premium
                                    </li>
                                </ul>
                            </div> -->
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="single-courses-box mb-30">
                            <div class="courses-image">
                                <a routerLink="/c" class="d-block"><img src="assets/images/CSN2.jpg"
                                        alt="image"></a>
                                <div class="courses-tag">
                                    <a routerLink="/" class="d-block">Premium</a>
                                </div>
                            </div>
                            <div class="courses-content">
                                <h3><a routerLink="/c" class="d-inline-block">Animated Adventures - Bringing Websites to Life with JavaScript</a></h3>
                                <div class="courses-rating">
                                    <div class="review-stars-rated">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                    </div>
                                    <div class="rating-total">
                                        4.9 (4 ratings)
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="courses-box-footer">
                                <ul>
                                    <li class="students-number">
                                        <i class='bx bx-user'></i> 10 students
                                    </li>
                                    <li class="courses-lesson">
                                        <i class='bx bx-book-open'></i> 6 lessons
                                    </li>
                                    <li class="courses-price">
                                        Premium
                                    </li>
                                </ul>
                            </div> -->
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="single-courses-box mb-30">
                            <div class="courses-image">
                                <a routerLink="/c" class="d-block"><img src="assets/images/CSN1.jpg"
                                        alt="image"></a>
                                <div class="courses-tag">
                                    <a routerLink="/c" class="d-block">Premium</a>
                                </div>
                            </div>
                            <div class="courses-content">
                                <h3><a routerLink="/c" class="d-inline-block">Young Minds, Digital Dreams - Dive into Web Development Basics</a></h3>
                                <div class="courses-rating">
                                    <div class="review-stars-rated">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                    </div>
                                    <div class="rating-total">
                                        5.0 (3 ratings)
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="courses-box-footer">
                                <ul>
                                    <li class="students-number">
                                        <i class='bx bx-user'></i> 10 students
                                    </li>
                                    <li class="courses-lesson">
                                        <i class='bx bx-book-open'></i> 6 lessons
                                    </li>
                                    <li class="courses-price">
                                        Premium
                                    </li>
                                </ul>
                            </div> -->
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="single-courses-box mb-30">
                            <div class="courses-image">
                                <a routerLink="/c" class="d-block"><img src="assets/images/CSN2.jpg"
                                        alt="image"></a>
                                <div class="courses-tag">
                                    <a routerLink="/c" class="d-block">Premium</a>
                                </div>
                            </div>
                            <div class="courses-content">
                                <h3><a routerLink="/c" class="d-inline-block">Web Warriors Workshop - Mastering HTML, CSS, and JavaScript</a></h3>
                                <div class="courses-rating">
                                    <div class="review-stars-rated">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                    </div>
                                    <div class="rating-total">
                                        4.8 (5 ratings)
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="courses-box-footer">
                                <ul>
                                    <li class="students-number">
                                        <i class='bx bx-user'></i> 10 students
                                    </li>
                                    <li class="courses-lesson">
                                        <i class='bx bx-book-open'></i> 6 lessons
                                    </li>
                                    <li class="courses-price">
                                        Premium
                                    </li>
                                </ul>
                            </div> -->
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="single-courses-box mb-30">
                            <div class="courses-image">
                                <a routerLink="/c" class="d-block"><img src="assets/images/CSN1.jpg"
                                        alt="image"></a>
                                <div class="courses-tag">
                                    <a routerLink="/c" class="d-block">Premium</a>
                                </div>
                            </div>
                            <div class="courses-content">
                                <h3><a routerLink="/c" class="d-inline-block">NextGen WebMasters - From Ideas to Interactive Webpages</a></h3>
                                <div class="courses-rating">
                                    <div class="review-stars-rated">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                    </div>
                                    <div class="rating-total">
                                        5.0 (7 ratings)
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="courses-box-footer">
                                <ul>
                                    <li class="students-number">
                                        <i class='bx bx-user'></i> 10 students
                                    </li>
                                    <li class="courses-lesson">
                                        <i class='bx bx-book-open'></i> 6 lessons
                                    </li>
                                    <li class="courses-price">
                                        Premium
                                    </li>
                                </ul>
                            </div> -->
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="single-courses-box mb-30">
                            <div class="courses-image">
                                <a routerLink="/cartc" class="d-block"><img src="assets/images/CSN2.jpg"
                                        alt="image"></a>
                                <div class="courses-tag">
                                    <a routerLink="/" class="d-block">Premium</a>
                                </div>
                            </div>
                            <div class="courses-content">
                                <h3><a routerLink="/c" class="d-inline-block">Code Creators - Web Programming Essentials for the Next Generation</a></h3>
                                <div class="courses-rating">
                                    <div class="review-stars-rated">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                    </div>
                                    <div class="rating-total">
                                        5.0 (3 ratings)
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="courses-box-footer">
                                <ul>
                                    <li class="students-number">
                                        <i class='bx bx-user'></i> 10 students
                                    </li>
                                    <li class="courses-lesson">
                                        <i class='bx bx-book-open'></i> 6 lessons
                                    </li>
                                    <li class="courses-price">
                                        Premium
                                    </li>
                                </ul>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>