import { Component, OnInit } from "@angular/core";
import { BeforeSlideDetail } from "lightgallery/lg-events";
import lgVideo from "lightgallery/plugins/video";

@Component({
    selector: "app-courses-details-page",
    templateUrl: "./courses-details-page.component.html",
    styleUrls: ["./courses-details-page.component.scss"],
})
export class CoursesDetailsPageComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}

    settings = {
        counter: false,
        plugins: [lgVideo],
    };
    onBeforeSlide = (detail: BeforeSlideDetail): void => {
        const { index, prevIndex } = detail;
        console.log(index, prevIndex);
    };

    accordionItems = [
        {
            title: "Module 1: Welcome to the World of JavaScript",
            content:
                `
                <ul>
                  <li>
                     Lesson 1.1: What is JavaScript?
                     <ul>
                        <li>Activity: Interactive story of how a static website becomes animated.</li>
                     </ul>
                  </li>
                  <li>
                     Lesson 1.2: First Steps: Writing Your First Script
                     <ul>
                        <li>Activity: Display a fun popup message on a webpage.</li>
                     </ul>
                  </li>
               </ul>
                `,
            open: false,
        },
        {
            title: "Module 2: Playing with Variables and Data",
            content:
                `
                <ul>
                  <li>
                     Lesson 2.1: What are Variables?
                     <ul>
                        <li>Activity: Create a digital treasure box storing treasures (data).</li>
                     </ul>
                  </li>
                  <li>
                     Lesson 2.2: Numbers, Text, and Booleans
                     <ul>
                        <li>Activity: Design a mini-quiz game.</li>
                     </ul>
                  </li>
               </ul>
                `,
            open: false,
        },
        {
            title: "Module 3: Making Decisions with JavaScript",
            content:
                `
                <ul>
                  <li>
                     Lesson 3.1: If This, Then That!
                     <ul>
                        <li>Create a simple weather app that suggests outfits.</li>
                     </ul>
                  </li>
                  <li>
                     Lesson 3.2: Loops: The Magic Repeaters
                     <ul>
                        <li>Activity: Design a repetitive animation, like a bouncing ball.</li>
                     </ul>
                  </li>
               </ul>
                `,
            open: false,
        }
    ];

    selectedItem = null;

    toggleAccordionItem(item) {
        item.open = !item.open;
        if (this.selectedItem && this.selectedItem !== item) {
            this.selectedItem.open = false;
        }
        this.selectedItem = item;
    }
}
