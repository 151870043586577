import {Component, OnInit} from '@angular/core';
import {NgxGalleryLayout, NgxGalleryOptions} from '@kolkov/ngx-gallery';
import {NgxGalleryImage} from '@kolkov/ngx-gallery';
import {NgxGalleryAnimation} from '@kolkov/ngx-gallery';

@Component({
  selector: 'app-course-one',
  templateUrl: './course1.component.html',
  styleUrls: ['./course1.component.css']
})
export class CourseOneComponent implements OnInit {
  galleryOptions: NgxGalleryOptions[] = [];
  galleryImages: NgxGalleryImage[] = [];
  
  constructor() { }

  ngOnInit() {
    this.galleryOptions = [
      {
        //width: '600px',
        //height: '400px',
        thumbnailsColumns: 3,
        imageAnimation: NgxGalleryAnimation.Slide,
        //thumbnailsRemainingCount: true,
      },
      // max-width 800
      {
        //breakpoint: 800,
        //width: '100%',
        //height: '600px',
        //imagePercent: 80,
        // thumbnailsPercent: 20,
        // thumbnailsMargin: 20,
        // thumbnailMargin: 20,
        layout: NgxGalleryLayout.ThumbnailsTop,
        thumbnailsArrows: true
      },
      // max-width 400
      {
        breakpoint: 400,
        preview: false
      }
    ];

    this.galleryImages = [
      {
        small: 'assets/images/H1.jpg',
        medium: 'assets/images/H1.jpg',
        big: 'assets/images/H1.jpg'
      },{
        small: 'assets/images/H2.jpg',
        medium: 'assets/images/H2.jpg',
        big: 'assets/images/H2.jpg'
      }
      ,{
        small: 'assets/images/H3.jpg',
        medium: 'assets/images/H3.jpg',
        big: 'assets/images/H3.jpg'
      }
      ,{
        small: 'assets/images/H4.jpg',
        medium: 'assets/images/H4.jpg',
        big: 'assets/images/H4.jpg'
      },{
        small: 'assets/images/H5.jpg',
        medium: 'assets/images/H5.jpg',
        big: 'assets/images/H5.jpg'
      },{
        small: 'assets/images/H6.jpg',
        medium: 'assets/images/H6.jpg',
        big: 'assets/images/H6.jpg'
      },{
        small: 'assets/images/H7.jpg',
        medium: 'assets/images/H7.jpg',
        big: 'assets/images/H7.jpg'
      },{
        small: 'assets/images/H8.jpg',
        medium: 'assets/images/H8.jpg',
        big: 'assets/images/H8.jpg'
      },{
        small: 'assets/images/H9.jpg',
        medium: 'assets/images/H9.jpg',
        big: 'assets/images/H9.jpg'
      }

    ];
  }
}