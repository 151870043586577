<section class="mission-area ptb-100">
    <div class="container">
        <div class="mission-content">
            <div class="section-title text-start">
                <span class="sub-title">Benefits of learning coding</span>
            </div>
            <div class="mission-slides">
                <owl-carousel-o [options]="missionSlides">
                    <ng-template carouselSlide>
                        <h2>Brain Gym:</h2>
                        <h3>Just like exercise for muscles, coding makes your brain stronger.</h3>
                        <a routerLink="/quick-peeks" class="default-btn"><i class='bx bx-book-reader icon-arrow before'></i><span class="label">View Courses</span><i class="bx bx-book-reader icon-arrow after"></i></a>
                    </ng-template>
                    <ng-template carouselSlide>
                        <h2>Problem Solving:</h2>
                        <h3>Helps you figure things out, like puzzles.</h3>
                        <a routerLink="/quick-peeks" class="default-btn"><i class='bx bx-book-reader icon-arrow before'></i><span class="label">View Courses</span><i class="bx bx-book-reader icon-arrow after"></i></a>
                    </ng-template>
                    <ng-template carouselSlide>
                        <h2>Memory Boost:</h2>
                        <h3> Remembering code helps improve memory.</h3>
                        <a routerLink="/quick-peeks" class="default-btn"><i class='bx bx-book-reader icon-arrow before'></i><span class="label">View Courses</span><i class="bx bx-book-reader icon-arrow after"></i></a>
                    </ng-template>
                    <ng-template carouselSlide>
                        <h2>Better Decisions:</h2>
                        <h3>Learn to make good choices faster.</h3>
                        <a routerLink="/quick-peeks" class="default-btn"><i class='bx bx-book-reader icon-arrow before'></i><span class="label">View Courses</span><i class="bx bx-book-reader icon-arrow after"></i></a>
                    </ng-template>
                    <ng-template carouselSlide>
                        <h2>Thinking Ahead:</h2>
                        <h3>Plan what you want your computer to do next.</h3>
                        <a routerLink="/quick-peeks" class="default-btn"><i class='bx bx-book-reader icon-arrow before'></i><span class="label">View Courses</span><i class="bx bx-book-reader icon-arrow after"></i></a>
                    </ng-template>
                    
                    <ng-template carouselSlide>
                        <h2>Being Creative:</h2>
                        <h3>Imagine and make your own stories or games.</h3>
                        <a routerLink="/quick-peeks" class="default-btn"><i class='bx bx-book-reader icon-arrow before'></i><span class="label">View Courses</span><i class="bx bx-book-reader icon-arrow after"></i></a>
                    </ng-template>
                    
                    <ng-template carouselSlide>
                        <h2>Patience:</h2>
                        <h3>If something doesn't work, try again until it does.</h3>
                        <a routerLink="/quick-peeks" class="default-btn"><i class='bx bx-book-reader icon-arrow before'></i><span class="label">View Courses</span><i class="bx bx-book-reader icon-arrow after"></i></a>
                    </ng-template>
                    
                    <ng-template carouselSlide>
                        <h2>Attention Boost:</h2>
                        <h3>Helps you focus and not miss small details.</h3>
                        <a routerLink="/quick-peeks" class="default-btn"><i class='bx bx-book-reader icon-arrow before'></i><span class="label">View Courses</span><i class="bx bx-book-reader icon-arrow after"></i></a>
                    </ng-template>
                    
                    <ng-template carouselSlide>
                        <h2>Understanding Steps:</h2>
                        <h3>Learn to do things in the right order.</h3>
                        <a routerLink="/quick-peeks" class="default-btn"><i class='bx bx-book-reader icon-arrow before'></i><span class="label">View Courses</span><i class="bx bx-book-reader icon-arrow after"></i></a>
                    </ng-template>
                    
                    <ng-template carouselSlide>
                        <h2>Making Connections:</h2>
                        <h3>See how different ideas fit together.</h3>
                        <a routerLink="/quick-peeks" class="default-btn"><i class='bx bx-book-reader icon-arrow before'></i><span class="label">View Courses</span><i class="bx bx-book-reader icon-arrow after"></i></a>
                    </ng-template>
                    
                </owl-carousel-o>
            </div>
        </div>
    </div>
</section>