import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-page-five',
  templateUrl: './home-page-five.component.html',
  styleUrls: ['./home-page-five.component.scss']
})
export class HomePageFiveComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  currentTab = 'tab1';
  switchTab(event: MouseEvent, tab: string) {
      event.preventDefault();
      this.currentTab = tab;
  }
  
}
